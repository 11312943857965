<template>
  <div class="navbar_wrapper container-fluid p-0 shadow">
    <b-navbar class="container-lg py-0" print>
      <b-navbar-brand class="mr-auto" :to="{name:'index'}">Wakaru.Org</b-navbar-brand>
      <b-navbar-nav>
        <LanguageSelector />
        <ThemeSelector />
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import LanguageSelector from '@/components/ui/LanguageSelector.vue';
import ThemeSelector from '@/components/ui/ThemeSelector.vue';

export default {
  name: 'AuthNavbar',
  components: {
    LanguageSelector, ThemeSelector,
  },

};
</script>
